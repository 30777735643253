/** Show resume
 * Props: none
 * Routes -> Resume
 */

function Resume() {
  return (
    <>
    </>
  );
}

export default Resume;